<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>生产工具</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 标贝数据处理 -->
    <el-card class="box-card-biaobei">
      <div slot="header" style="overflow: hidden;">
        <!-- <span>卡片名称</span> -->
        <h3 style="float: left;margin: 0 0;">标贝数据处理</h3>
      </div>
      <div class="div-upload">
        <!-- <h5>参考类型：</h5> -->
        <el-form :inline="true" :model="biaobeiRuleForm" :rules="rules" ref="biaobeiRuleFormRef" label-width="100px">
          <el-form-item label="参看类型" prop="run_type">
            <el-select v-model="biaobeiRuleForm.run_type" placeholder="请选择参考类型">
              <el-option
                v-for="item in biaobeiReferOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <el-button class="biaobei-button" type="primary" @click="biaobeiDialogUp">点击上传 文件</el-button>
        <el-dialog
          title="提示"
          :visible.sync="biaobeiDialogVisible"
          width="30%">
          <el-upload
            class="upload-biaobei"
            drag
            action=""
            :before-upload="biaobeiBeforeUpload"
            :file-list="biaobeiFileList"
            :http-request="biaobeiHandleUpload"
            ref="biaobeiUploadRef"
            >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
          <span slot="footer" class="dialog-footer">
            <el-button @click="biaobeiDialogVisibleClose">取 消</el-button>
            <el-button type="primary" @click="biaobeiDialogVisibleUpload">确 定</el-button>
          </span>
        </el-dialog>

        <img v-if="importExcelText==='待上传'" src="@/assets/info.png" >
        <img v-if="importExcelText==='已成功'" src="@/assets/successful.png" >
        <h4 style="margin: 0 0;">{{ importExcelText }}</h4>
      </div>
    </el-card>

    <!-- 阿维塔月报数据 -->
    <el-card class="box-card-yuebao">
      <div slot="header" style="overflow: hidden;">
        <!-- <span>卡片名称</span> -->
        <h3 style="float: left;margin: 0 0;">阿维塔月报数据处理</h3>
      </div>
      <div class="div-upload-yuebao">
        <!-- <el-upload
          class="upload-yuebao"
          action="https://jsonplaceholder.typicode.com/posts/"
          :http-request="handleDelUploadMonth"
          :show-file-list="false">
          <el-button type="primary" icon="el-icon-upload2">导入EXCEL</el-button>
        </el-upload> -->
        <el-form :inline="true" :model="avtMonthRuleForm" :rules="rules" ref="avtMonthRuleFormRef">
          <el-form-item label="选择问卷" prop="survey_id">
            <el-select
              v-model="avtMonthRuleForm.survey_id"
              multiple
              collapse-tags
              style="width: 280px;"
              placeholder="请选择">
              <el-option
                v-for="item in survey_name_list"
                :key="item.survey_id"
                :label="item.survey_name"
                :value="item.survey_id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="选择时间" prop="avt_month_picker_data">
            <el-date-picker
              class="data-picker-class-month"
              v-model="avtMonthRuleForm.avt_month_picker_data"
              type="datetimerange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :value-format="'yyyy-MM-ddTHH:mm:ss.SSSZ'"
              :default-time="['00:00:00','23:59:59']">
            </el-date-picker>
          </el-form-item>

          <el-form-item label="填写accessToken" prop="accessToken">
            <el-input v-model="avtMonthRuleForm.accessToken"></el-input>
          </el-form-item>

          <el-button type="primary" @click="avtMonthReportHandle">开始处理</el-button>

        </el-form>

        <img v-if="exportAvtExcelText==='待上传'" src="@/assets/info.png" >
        <img v-if="exportAvtExcelText==='已成功'" src="@/assets/successful.png" >
        <h4 style="margin: 0 0;width: 100px;">{{ importExcelText }}</h4>
      </div>
    </el-card>

    <!-- 阿维塔问卷开放题处理 -->
    <el-card class="box-card-avt-survey">
      <div slot="header" style="overflow: hidden;">
        <!-- <span>卡片名称</span> -->
        <h3 style="float: left;margin: 0 0;">阿维塔问卷开放题处理(暂未接入)</h3>
      </div>
      <div class="div-upload">
        <el-upload
          class="upload-demo"
          drag
          action="https://jsonplaceholder.typicode.com/posts/"
          multiple>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
        </el-upload>
        <img v-if="importExcelText==='待上传'" src="@/assets/info.png" >
        <img v-if="importExcelText==='已成功'" src="@/assets/successful.png" >
        <h4 style="margin: 0 0;">{{ importExcelText }}</h4>
      </div>
    </el-card>
  </div>
</template>

<script>
import { BiaobeiExcelHandle, avtMonthReportExcelHandle } from '@/services/productionTools.js'
import { saveAs } from 'file-saver'
export default {
  name: 'ProductionTools',
  data () {
    return {
      value2: '',
      importExcelText: '待上传',
      exportAvtExcelText: '待上传',
      avtMonthExport: '',
      biaobeiReferOptions: [{
        value: 'old',
        label: '旧参考文件'
      }, {
        value: 'new',
        label: '新参考文件'
      }],
      biaobeiRuleForm: {
        run_type: ''
      },
      rules: {
        run_type: [
          { required: true, message: '请选择参考类型', trigger: 'change' }
        ],
        survey_id: [
          { required: true, message: '请选择问卷', trigger: 'change' }
        ],
        accessToken: [
          { required: true, message: '请输入accessToken', trigger: 'blur' }
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        avt_month_picker_data: [
          { required: true, message: '请选择日期和时间', trigger: 'change' }
        ]
      },
      biaobeiDialogVisible: false,
      biaobeiFileList: [],
      avtMonthRuleForm: {
        survey_id: [],
        accessToken: '',
        avt_month_picker_data: ''
      },
      survey_name_list: [
        {
          survey_id: 'm3aZYT',
          survey_name: '阿维塔体验调查问卷-进店维保'
        }, {
          survey_id: 'reF9rH',
          survey_name: '阿维塔体验调查问卷-交付'
        }, {
          survey_id: 'et0G0Z',
          survey_name: '阿维塔体验调查问卷-试乘试驾'
        }, {
          survey_id: 'BWOy8s',
          survey_name: '阿维塔体验调查问卷-下定'
        }
      ]
    }
  },
  methods: {
    // biaobeiChangeHandleFile (file, biaobeiFileList) {
    //   console.log(file, '<--change请求数据文件')
    //   this.biaobeiFileList = biaobeiFileList.slice(-3)
    //   console.log(this.biaobeiFileList, '<--fileList数据')
    // },
    // handleDelUpload () {
    //   console.log(this.biaobeiFileList)
    // },
    dateChangeHandle () {
      console.log(this.value2)
      console.log(Object.prototype.toString.call(this.value2).slice(8, -1))
    },
    biaobeiDialogUp () {
      this.$refs.biaobeiRuleFormRef.validate((valid) => {
        if (valid) {
          this.biaobeiDialogVisible = true
        } else {
          this.$message.error('请选择参考类型')
          return false;
        }
      });
    },
    biaobeiBeforeUpload (file) {
      const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel';
      const isSizeValid = file.size / 1024 <= 50000; // 文件大小不超过50MB

      if (!isExcel) {
        this.$message.error('只能上传xlsx/xls文件');
        return false;
      }

      if (!isSizeValid) {
        this.$message.error('文件大小不能超过50MB');
        return false;
      }

      return true
    },
    biaobeiDialogVisibleClose () {
      this.biaobeiDialogVisible = false
      this.biaobeiFileList = []
    },
    biaobeiHandleUpload (options) {
      console.log(options, '<-- 自定义请求数据')
      this.biaobeiFileList.push(options.file)
      console.log(this.biaobeiFileList, '<-- 添加的文件列表')
    },
    async biaobeiDialogVisibleUpload () {
      // 使用 form/data 类型 请求数据 并且组装请求数据
      this.importExcelText = '待上传'
      const fd = new FormData()
      this.biaobeiFileList.forEach(file => {
        console.log(file, '--wenjia请求数据')
        fd.append('file', file);
      });
      // fd.append('file', this.biaobeiFileList)
      fd.append('run_type', this.biaobeiRuleForm.run_type)

      // 发送请求
      // const { data: res } = await BiaobeiExcelHandle(fd)
      const result = await BiaobeiExcelHandle(fd)
      console.log(result)
      if (result.status === 200) {
        this.importExcelText = '已成功'
        this.biaobeiDialogVisible = false
        this.biaobeiFileList = []
      }
      const res = result.data
      console.log(res.status)
      console.log(res, '<--上传后的返回数据')
      const blob = new Blob([res], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      })
      saveAs(blob, '处理后的excel.xlsx')
    },
    async avtMonthReportHandle () {
      this.$refs.avtMonthRuleFormRef.validate(async (valid) => {
        if (valid) {
          const avtObj = {
            projectId: this.avtMonthRuleForm.survey_id,
            type: 'answer',
            pageSize: '-1',
            survey_info: this.survey_name_list,
            accessToken: this.avtMonthRuleForm.accessToken,
            startTime: this.avtMonthRuleForm.avt_month_picker_data[0],
            endTime: this.avtMonthRuleForm.avt_month_picker_data[1]
          }
          console.log(avtObj, '<-- 要上传的阿维塔月报数据')
          const result = await avtMonthReportExcelHandle(avtObj)

          console.log(result.status, '<-- 返回的码')
          console.log(result.headers['content-type'], '<-- 返回的类型')
          console.log(result.data, '<-- 返回的data')

          if (result.status === 200) {
            if (result.headers['content-type'] === 'application/json') {
              return this.$message.error('请更换assessToken')
            }
            if (result.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
              this.exportAvtExcelText = '已成功'
              this.$refs.avtMonthRuleFormRef.resetFields()

              const res = result.data
              console.log(res.status, '<-- 返回的data内容')
              console.log(res, '<--上传后的返回数据')
              const blob = new Blob([res], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              })
              saveAs(blob, '处理后的excel.xlsx')
            }
          }
        } else {
          this.$message.error('数据未填写')
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>

  .box-card-biaobei {
    margin: 20px 0;
    height: 150px;
    overflow: hidden;
  }
  .biaobei-selectRuleForm {
    margin-top: 10px;
  }
  .div-upload {
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .biaobei-button {
    margin-left: 30px;
  }
  .upload-biaobei {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .box-card-yuebao {
    margin: 20px 0;
    overflow: hidden;
  }
  .div-upload-yuebao {
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .data-picker-class {
    margin: 0 20px 0px 20px;
  }
  img {
    height: 30px;
    width: 30px;
    margin-left: 30px;
  }

</style>

import request from '@/utils/request.js'

// 导入EXCEL文件
export const BiaobeiExcelHandle = (data) => {
  return request({
    method: 'POST',
    url: 'biaobeiExcelHandle',
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}

// 阿维塔月报数据处理
export const avtMonthReportExcelHandle = (data) => {
  return request({
    method: 'POST',
    url: 'avtMonthReport',
    responseType: 'arraybuffer',
    data
  })
}
